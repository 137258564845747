@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.shadow {
  box-shadow: 0 2px 4px 0 rgba(1, 41, 112, 0.2);
  /* box-shadow: 0 7px 20px 0 rgb(1 41 112 / 15%); */
  transition: all 0.5s;
}

.h-skeleton {
  height: calc(100vh - 65px);
}

.w-skeleton {
  width: calc(100% - 240px);
}

.h-skeleton-datagrid {
  width: 100%;
  height: calc(100vh - 65px - 32px - 80px);
}

.shadow-skeleton {
  box-shadow: 0 7px 20px 0 rgb(1 41 112 / 15%);
  transition: all 0.5s;
}

.lastChild:last-child {
  margin-bottom: 10px;
}

.sidebar {
  display: none;
  width: 250px;
  height: calc(100vh - 65px);
  background-color: rgb(255, 255, 255);
  padding: 10px;
  position: relative;
  z-index: 49;
  left: -250px;
  transition: 0.4s ease-in;
}

.test-row {
  background-color: red;
}

input[type="file"] {
  display: none;
}

.sidebar.is-active {
  display: block;
  position: fixed;
  background-color: rgb(255, 255, 255);
  left: 0;
  transition: 0.4s ease-in;
}

.mainactive {
  width: calc(100% - 250px);
  position: absolute;
  transform: translate(250px);
  transition: all linear 0.2s;
  padding: 16px;
}

@media only screen and (max-width: 1024px) {
  .mainactive {
    width: 100%;
    transform: none;
  }
}

.maincontent {
  width: 100%;
  transition: all linear 0.2s;
  padding: 16px;
}

.calculated-height {
  height: calc(100vh - 65px - 32px);
}

.calculated-height-assign {
  height: calc(100vh - 65px - 32px - 100px);
}

.calculated-height-execute {
  height: calc(100vh - 65px - 32px - 1.25rem - 80px);
}
.calculated-height-equipment {
  height: calc(100vh - 65px - 32px - 1.25rem - 205px);
}

.calculated-height-programacion {
  height: calc(100vh - 65px - 32px - 1.25rem - 20px);
}

.text-image {
  background-image: url("./assets/login-bg.svg");
}

.bg-img {
  background-image: url("./assets/login-bg.svg");
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.border-gray {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1);
}

.border-container {
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 5px;
  min-height: calc(100vh - 65px - 32px - 65px - 35px);
  border-color: rgba(224, 224, 224, 1);
}

.border-container-programming {
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 5px;
  min-height: calc(100vh - 65px - 32px - 65px - 90px);
  border-color: rgba(224, 224, 224, 1);
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  /* border: 1px solid #2B2C84;
  color: #2B2C84; */
  display: inline-block;
  padding: 5px 10px;
  /* height: 35px; */
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 10px;
  cursor: pointer;
}

.sidebar-scroll {
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: auto;
}

.sidebar-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}

.preFactura-scroll {
  min-height: 95%;
  max-height: 95%;
  overflow-y: auto;
}

.preFactura-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
}

.preFactura-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
}

.preFactura-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.preFactura-scroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}

.input-number::-webkit-inner-spin-button,
.input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

.react-pdf__Page__canvas {
  z-index: -20 !important;
}

.pdf-scroll {
  overflow-y: auto;
}

.pdf-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
}

.pdf-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.pdf-scroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}
